import React from "react"
import { Link } from 'gatsby';
import useCookieDialoge from '../hooks/useCookieDialoge'

const CookieDialoge = () => {
    const { accept, isAccepted } = useCookieDialoge();

    if (isAccepted())
        return null
    return (
        <div className="fixed bottom-0 left-0 rounded bg-white m-8 py-4 px-8 z-50 border-gray-400 border-2">
            <span className="text-xs text-gray-600 italic">
                By continuing to use this site, you are agreeing to our <Link to="/cookie-policy/" className="hover:text-gray-800 underline">cookie policy</Link>.
            </span>
            <button 
                className="px-4 py-4 border border-transparent text-base font-small rounded-md text-red-800 bg-indigo-100 hover:text-red-800 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out"
                onClick={accept} 
            >
                Accept
            </button>
        </div>
    )
};

export default CookieDialoge
