import React from "react";
import logoPng from '../images/reedmaker-logo-web.png'

function ReedmakerLogo() {
    return (
        <img
            alt="Reedmaker Reed shop"
            src={logoPng} 
            style={{
                width: 150,
            }}
        />
    )
}

export default ReedmakerLogo;
