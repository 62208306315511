import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

const MainMenu = () => (
  <StaticQuery
    query={graphql`
    query {
        wpgraphql {
          menus(where: {location: PRIMARY}) {
            nodes {
              name
              menuItems {
                nodes {
                  label
                  page: connectedObject {
                    ... on WPGraphQL_Page {
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <nav>
        <ul className="md:flex items-center justify-between text-base text-gray-700">
        {data.wpgraphql.menus.nodes[0].menuItems.nodes.map(menu => (
          <Link
              key={menu.page.uri}
              to={menu.page.uri}
              className="inline-block py-5 px-1 sm:mx-1 no-underline hover:text-red-800 hover:border-red-800 border-b-2 border-white"
              activeClassName="text-red-800 border-red-800"

              // For children of the parent
              getProps={({ isPartiallyCurrent }) =>
                isPartiallyCurrent ? { className: "inline-block py-5 px-1 sm:mx-1 no-underline hover:text-red-800 hover:border-red-800 border-b-2 border-white text-red-800 border-red-800" } : null
              }
            >
              <span className="text-sm">{menu.label}</span>
            </Link>
        ))}
        </ul>
      </nav>
    )}
  />
)

export default MainMenu