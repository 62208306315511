import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby'
import MainMenuLinks from './mainMenuLinks'
import ReedmakerLogo from '../ReedmakerLogo';
import { useCart } from '../../hooks/useCart';

// https://www.freecodecamp.org/news/how-to-style-your-react-apps-with-less-code-using-tailwind-css-and-styled-components/

const ShoppingCart = () => {
    const { isEmpty } = useCart();

    return (
        <Link className={`${isEmpty ? '' : 'text-red-800 md:text-black lg:text-black' } inline-block no-underline hover:text-red-800`} to="/cart">
            { !isEmpty && 
                <span className="absolute ml-5 w-2 h-2 bg-red-800 rounded-full invisible md:visible">
                    <span className="p-2">Checkout</span>
                </span>
            }
            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z" />
                <circle cx="10.5" cy="18.5" r="1.5" />
                <circle cx="17.5" cy="18.5" r="1.5" />
            </svg>
        </Link>
    )   
}

const Logo = ({ siteTitle }) => (
    <Link to="/" className="flex items-center tracking-wide no-underline hover:no-underline font-bold text-gray-800 hover:text-red-800 text-xl py-5 md:py-0">
        <ReedmakerLogo />
    </Link>
)

const Hamburger = () => (
    <svg className="fill-current text-gray-900 hover:text-red-800" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
    </svg>
)

const Navbar = ({ siteTitle }) => (
    <nav id="header" className="fixed w-full z-30 top-0 bg-white shadow" >
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-6">

            <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block">
                <Hamburger />
            </label>
            <input className="hidden" type="checkbox" id="menu-toggle" />

            <div className="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1" id="menu">
                <MainMenuLinks />
            </div>

            <div className="order-1 md:order-2">
                <Logo siteTitle={siteTitle} />
            </div>

            <div className="order-2 md:order-3 flex items-center">
                <ShoppingCart />
            </div>
        </div>
    </nav>
)

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
