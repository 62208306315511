import React from "react";

function CaptchaLegal() {
    return (
        <span className="text-xs text-gray-600 italic">
            This site is protected by reCAPTCHA and the Google <a className=" hover:text-gray-800 underline" href="https://policies.google.com/privacy">Privacy Policy</a> and <a className=" hover:text-gray-800 underline" href="https://policies.google.com/terms">Terms of Service</a> apply.
        </span>
    )
}

export default CaptchaLegal;




