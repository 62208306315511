import { useState, useEffect } from 'react';
import useLocalStorage from "./useLocalStorage";

function useCookieDialoge() {
    const [isLoading, setIsLoading] = useState(true)
    const [isAccepted, setIsAccepted] = useLocalStorage("reedmaker-accepted", "0");
  
    useEffect(() => {
        setIsLoading(false)
    }, []);

    return {
        accept: () => setIsAccepted("1"),
        isAccepted: () => isLoading || isAccepted === "1",
    }
  }
  
  export default useCookieDialoge;